<template>
  <HXContentCard title="Nueva Area" :toBack="{ name: 'Areas' }">
    <form @submit.prevent="saveData">
      <div class="row p-4">
        <div class="col-xl-6">
          <div>
            <label for=" " class="form-label">Nombre Área</label>
            <input
              required
              v-model="item.Descripcion"
              placeholder="Escriba el Nombre..."
              type="text"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-xl-6">
          <div class="mb-3">
            <label for=" " class="form-label">Sucursal</label>
            <b-form-select
              @change.native="actualizaDepto($event)"
              v-model="item.SucursalId"
              required
              text-field="descripcion"
              value-field="id"
              :options="ListSucursales"
            >
              <template #first>
                <b-form-select-option :value="''" disabled>
                  -- Seleccionar sucursal --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="col-xl-6">
          <div class="mb-3">
            <label for=" " class="form-label">Empresa</label>
            <b-form-select
              :disabled="usuario.rol.nivel != 4 ? true : false"
              @change.native="actualizaSucursal($event)"
              required
              v-model="item.EmpresaId"
              value-field="id"
              text-field="nombre"
              :options="ListEmpresas"
            >
              <template #first>
                <b-form-select-option :value="''" disabled>
                  -- Seleccionar empresa --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="mb-3">
            <label for=" " class="form-label">Departamento</label>
            <b-form-select
              required
              v-model="item.DepartamentoId"
              value-field="id"
              text-field="descripcion"
              :options="ListDepartamento"
            >
              <template #first>
                <b-form-select-option :value="''" disabled>
                  -- Seleccionar departamento --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button icon animation-type="vertical" type="submit" class="mx-1">
          <i class="fas fa-save pr-1"></i>
          Guardar Area
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </HXContentCard>
</template>
<script>
// import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  data() {
    return {
      id: "",
      selected: null,
      item: {
        EmpresaId: "",
        SucursalId: "",
        Descripcion: "",
        Estado: 1,
        DepartamentoId: "",
      },
      ListEmpresas: [],
      ListSucursales: [],
      ListDepartamento: [],
      usuario: {},
      depto: "",
      sucursal: "",
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log("***************", this.usuario);
    this.item.EmpresaId = this.usuario.empresa.id;
    await this.getEmpresas();
    await this.getSucursalEmpresa(this.usuario.empresa.id);
    //await this.getDepartamentoEmpresa();
    this.$isLoading(false);
  },
  methods: {
    async actualizaSucursal(e) {
      console.log(e.target.value);
      //this.sucursal = e.target.value;
      await this.getSucursalEmpresa(this.item.EmpresaId);
    },
    async actualizaDepto(e) {
      console.log(e.target.value);
      this.sucursal = e.target.value;
      await this.getDepartamentoSucursal(this.sucursal);
    },

    async getDepartamentoSucursal() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Departamento/ListDepartamentoSucursal/" + this.sucursal,
        });
        this.ListDepartamento = res;
        console.log("departamentosooooooooooooo ", res);
      } catch (error) {
        this.ListDepartamento = {};
        console.log("err", error);
      }
    },
    async getSucursalEmpresa(empresaId) {
      //console.log(this.persona.empresa.id);
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + empresaId,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log("saving", this.item);
      try {
        //this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        //this.item.Descripcion = this.item.descripcion;
        this.item.Estado = 1;

        let res = await this.$store.dispatch("hl_post", {
          path: "Area/Create/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/Areas");
        else alert("Verificar");
      } catch (error) {
        //this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
